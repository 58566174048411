@media only screen and (max-width: 1200px) and (max-height: 2550px) {
  /* mobile.css */
  .App-header {
    flex-wrap: wrap;
    margin-bottom: 50px;
    padding-bottom: 20px;
    z-index: 2;
  }

  .App-header li {
    margin-bottom: -15px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 8px;
    width: 23%;
    font-size: 14px;
    font-weight: bold;
  }

  .App-header li a {
    text-decoration: none;
    color: #fff;
  }

  .App-header li:hover {
    background-color: rgb(0, 0, 0);
    transition: all 0.5s ease;
  }



.logo{
        display: flex;
        justify-content: center; /* horizontally center the logo */
        align-items: center; /* vertically center the logo */
        cursor: pointer;
        transition: all 0.5s ease;
        margin-top: 50px;
        margin-bottom: 0px;

        
    }
    .logo img{
        width: 375px;
        margin-left: 15px;
      }

    .backgroundhome img{
        height: 100%;
        width: 95%;
        margin-left: 9px;
        margin-right: 10px;
        margin-top: 60px;
        margin-bottom: 70px;
    }
    .Center {
        margin-top: 275px;
        padding-bottom: 500px;
        
      }
    .Center h1{
        font-size: 30px;
        font-weight: bold;
        z-index: 2;      
      }
    .Center p{
        font-size: 10px;
        font-weight: bold;
        z-index: 2;
        filter: brightness(80%);
      }
    .Center button{
        margin-top: 30px;
        background-color: transparent;
        box-shadow: 0 6px 6px rgb(0, 0, 0, 0.6);
        border: 1px solid #fff;
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        color: #fff;
        font-size: 10px;
      }
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
        padding-bottom: 7rem;
      }
      .container2 p{
        margin-left: 60px;
        margin-right: 60px;
      }

      .card h1{
        font-size: 25px;
        font-weight: bold;
        color: #fff;
        padding-top: 40px;
        z-index: 2;
        padding-bottom: 3rem;
      }
      .box {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
        border-radius: 1rem;
        width: 170px;
        border: 1px solid #fff;
      }
      .box-container {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        height: 30px;
        color: #b38219;        ;
        padding-top: 25px;
      }
      .content h3{
        font-size: 12px;
        font-weight: bold;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        
      }
      
      .container p {
        font-size: 10px;
        line-height: 1.5;
      }
      .provide{
        padding-top: 5rem;
        padding-bottom: 3rem;
        background-color: rgb(167, 167, 167);
      }
      .provide h1{
        font-size: 20px;
      }
      .provide p{
        font-size: 9px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 1rem;
        z-index: 2;
        padding-bottom: 2rem;
      }
      .box-container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .box2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
    
  }

  .box2 .icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .box2 h2 {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .box2 p {
    font-size: 12px;
    margin-bottom: 0;
    margin-left: 40px;
  }

    /* RESIDENTAL AND COMMERCIAL */
    .Image{
        display: flex;
        flex-wrap: wrap;
    }
    .Residential h1{
        font-size: 30px;
        font-weight: bold;
        margin-top: 10px;
    }
    .Commercial h1{
        font-size: 30px;
        font-weight: bold;
        margin-top: 10px;
    }
    .Image1{
        width: 80%;
        box-sizing: border-box;
        padding: 5px;
        grid-template-columns: 1fr;
    }
    .Image2{
        width: 80%;
        box-sizing: border-box;
        padding: 5px;
    }

    .About{
        justify-content: center;
        align-items: center;
        background-color: #534e4e;
        z-index: 2;
      }
      
        /* ABOUT US */

      .about-us-content {
        width: 60%;
        padding: 3rem;
        margin-top: 20px;
        margin-left: 35px;
      }
      .go-to-contact button{
        background-color: #0077cc;
        color: #fff;
        border-radius: 0.25rem;
        border: none;
        font-size: 15px;
        margin-top: 1rem;
        cursor: pointer;
      }
      .About h2 {
        font-size: 20px;
        margin-bottom: 1rem;
        z-index: 2;
      }
      .divider p{
        line-height: 23px;
        font-size: 12px;
      }
      /* Contact us SECTION */
    .contact-us-container {
        width: 95%;
        margin-top: 25px;
        margin-left: 10px;
        margin-bottom: 25px;
    }
    
    .contact-info,
    .contact-form {
        padding: 1rem;
        height: 100%;
        font-size: 15px;
        border-right: 1px solid #534e4e;
    }
    
    .contact-info {
        display: flex;
        flex-direction: column;

        
    }
    
    .contact-info p{
        font-size: 12px;

    }
    form{
        font-size: 12px;

    }
    
    h2 {
        margin-bottom: 1rem;
    }
    
    input,
    textarea {
        margin: 0.5rem;
        border-radius: 0.25rem;
        border: 1px solid #ccc;
        font-size: 12px;
        width: 90%;
        max-width: 25rem;
    }
    
    .contact-form button {
        background-color: #0077cc;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        border: none;
        font-size: 1rem;
        margin-top: 1rem;
        cursor: pointer;
    }
    
    .contact-form button:hover {
        background-color: #005ea3;
    }
    .popup-images img{
      width: 95%;
    }
    .popup-prev,
    .popup-next {
      margin-left:0;
      font-size: 20px;
    }
}
 