.App{
  height: 10vh; /* set the height of the page to 100% of the viewport */
  width: 100vw; /* set the width of the page to 100% of the viewport */
  margin: 0; /* remove the default margin */
  padding: 0; /* remove the default padding */

}
.App-header{
  height: 100px;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  flex-wrap: wrap;

}
.App-header ul{
  background-color: black;

}
.App-header li{
  display: inline;
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
  transition: all 0.5s ease;
  text-decoration: none;
  background-color: #b38219;
  margin: 40px;
  border-radius: 20px; 
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px; 
}
.App-header li:hover{
  background-color: rgb(0, 0, 0);
  transition: all 0.5s ease;
}  
.App-header li a{
  color: #fff;
  text-decoration: none;
} 
.logo{
  display: flex;
  justify-content: center; /* horizontally center the logo */
  align-items: center; /* vertically center the logo */
  cursor: pointer;
  transition: all 0.5s ease;
  margin-top: 40px;
  margin-bottom: 30px;
  margin-right: 10px;
}
.logo img{
  background-color:transparent;
  width: 800px;
}
/* Home SECTION */
.home{
  overflow:scroll;
}
.backgroundhome img{
  width: 80%;
  height: 80%;
  margin-left: 10%;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0);
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.2);
  z-index: 2;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;
  filter: brightness(60%);
}
.Center {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #fff;
  font-family: 'Times New Roman', Times, serif;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;
  margin-top: 120px;
}
.Center h1{
  font-size: 70px;
  font-weight: bold;
  z-index: 2;

}
.Center p{
  font-size: 20px;
  font-weight: bold;
  z-index: 2;
  filter: brightness(80%);
}
.Center button{
  margin-top: 150px;
  background-color: transparent;
  box-shadow: 0 6px 6px rgb(0, 0, 0, 0.6);
  border: 1px solid #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 1rem;
}
.Center button:hover{
  background-color: #fff;
  color: rgb(0, 0, 0);
  transition: all 0.5s ease;
  z-index: 2;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(120, 115, 115);
  margin-top: 60px;
  padding-top: 1rem;
  padding-bottom: 3rem;
}
.card h1{
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 1rem;
  z-index: 2;
  padding-bottom: 2rem;
  font-family: 'Times New Roman', Times, serif;
}

.box-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.box {
  display: flex;
  flex-direction: column;
  margin: 0 0.75rem;
  background-color: #fff;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  width: 450px;
  border: 1px solid #fff;
  
}
.box:hover{
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  height: 70px;
  color: #b38219;
  padding-top: 25px;
}

.content {
  padding: 1rem;
}
.content h3{
  font-size: 15px;
  font-weight: bold;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;

}

.container p {
  font-size: 10px;
  line-height: 1.5;
  color: #444;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.provide{
  background-color: rgb(120, 115, 115);
  z-index: 2;
  padding-top: 1rem;
  padding-bottom: 3rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.provide h1{
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 1rem;
  z-index: 2;
  

}
.provide p{
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 1rem;
  z-index: 2;
  padding-bottom: 2rem;
}
.container2 p{
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 500px;
  margin-right: 500px;
}

.box-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box2 {
  width: 350px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.box2 h2 {
  font-size: 20px;

}

.box2 p {
  font-size: 10px;
  text-align: center;
  padding-right: 30px;
}

.box2 svg {
  font-size: 50px;
  color: #ffffff;

}

/* Contact us SECTION */
.contact-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  box-shadow: 0 0.5rem 0.5rem rgba(255, 255, 255, 0.2);
  background-color: white;
  border-radius: 0.5rem;
  margin-top: 60px;
  margin-left: 210px;
  margin-right: 210px;
  font-family: 'Times New Roman', Times, serif;
  z-index: 2;
}

.contact-info,
.contact-form {
  width: 50%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  height: 100%;
  font-family: 'Times New Roman', Times, serif;
  font-size: 15px;
  border-right: 1px solid #534e4e;
  z-index: 2;
}

.contact-info {
  display: flex;
  flex-direction: column;
  z-index: 2;
  
}

.contact-info p{
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.2rem;
  z-index: 2;
}
form{
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.2rem;
  z-index: 2;
}

h2 {
  margin-bottom: 2rem;
}

label {
  font-weight: bold;
  margin-top: 1rem;
}

input,
textarea {
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  font-size: 1rem;
  width: 100%;
  max-width: 25rem;
  z-index: 2;
  font-family: 'Times New Roman', Times, serif;
}

.contact-form button {
  background-color: #0077cc;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #005ea3;
}
/* About us SECTION */
.About{
  justify-content: center;
  align-items: center;
  background-color: #534e4e;
  z-index: 2;
}

.about-us-content {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  width: 50%;
  margin-top: 60px;
  margin-left: 300px;
  padding-top: 40px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  color: black;
  z-index: 2;
}
.go-to-contact button{
  background-color: #0077cc;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}


.About h2 {
  font-size: 20px;
  margin-bottom: 1rem;
  z-index: 2;
}
.divider p{
  line-height: 26px;
}
/*PROJECTS*/
.projects{
  background-color: #534e4e;
  z-index: 2;
}
.Residential,
.Commercial{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: 'Times New Roman', Times, serif;
  text-decoration-color: #fff;
  text-decoration-style: solid;
  font-size: 20px;
  z-index: 2;  
  padding-bottom: 10px;
}
.Residential h1 ,
.Commercial h1{
  font-size: 40px;
  z-index: 2;
  padding-bottom: 10px;
}

.Image1{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;
  margin: 0 auto;
  max-width: 1060px;
  min-width: 200px;
  z-index: 2;
  padding-bottom: 70px;
  cursor: pointer;



}
.Image1 img{
  width: 100%;

  border: 1px solid black;

}

.Image1 img:hover{
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
  
}
.Image2{
   display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;
  margin: 0 auto;
  max-width: 1060px;
  min-width: 200px;
  z-index: 2;
  padding-bottom: 70px;
  cursor: pointer;
  
}
.Image2 img{
  width: 100%;
  height: auto;
  border: 1px solid black;
}

.Image2 img:hover{
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
  
}

.popup-images {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-images img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.popup-prev,
.popup-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 20px;
  font-size: 40px;
  background: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
}

.popup-prev {
  left: 20px;
}

.popup-next {
  right: 20px;
}